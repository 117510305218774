import '../styles/Contact.css';
import contactImg from '../assets/contact.webp';

function Contact() {
  return (
    <section id='contact' className='contact-section'>
      <div className='contact-content'>
        <div className='contact-img-container'>
          <img src={contactImg} alt='Contact' />
        </div>
        <div className='contact-text'>
          <h2>Contactez-nous</h2>
          <p>Pour nous contacter, vous pouvez nous envoyer un email à l'adresse suivante:</p>
          <p><span>fabien.cicoletta@sfr.fr</span></p>
          <p>Ou nous appeler au numéro suivant:</p>
          <p><span>06.14.90.77.15</span></p>
        </div>
      </div>
    </section>
  );
}

export default Contact;

