

import React from 'react';
import '../styles/Timeline.css'

const Timeline = () => {

  const dates = [
    { title: "Ballet de l'opéra national du Capitole", description: "1997-2013" },
    { title: "Chanteur et danseur dans les spectacles du Casino Barrière de Toulouse", description: "2014-2019" },
    { title: "Création école de danse et de chant « Les feux de la Rampe »", description: "2016" },
    { title: "Création d’un spectacle « Retour sur la chanson française »", description: "2017-2019" },
    { title: "Participation TV « Show me your voice » sur M6  ", description: "2021" },
    { title: "Préparation d’un EP « CONFIDENCES »", description: "2021-2023" },
    { title: "The Voice saison 11", description: "2022" },
    { title: "Création du show CROONERS", description: "2024" },
  ];

  return (
    <div id='about'className="timeline-container">
      <h1>A Propos</h1>
      <h3>de <span>Fabien Cicoletta</span></h3>
      <ul className="timeline">
        {dates.map((date, index) => (
          <li className="timeline-item" key={index}>
            <div className="timeline-content">
              <h3 className="timeline-title">{date.title}</h3>
              <p className="timeline-description">{date.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Timeline;
