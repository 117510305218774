import React from 'react';
import '../styles/backbutton.css'
function BackToTopButton() {

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <button className="back-to-top-button" onClick={scrollToTop}>
      Retour en haut
    </button>
  );
}

export default BackToTopButton;
