import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import '../styles/Carousel.css'
import slide1 from '../assets/slide1.jpg';
import slide2 from '../assets/slide2.jpg';
import slide3 from '../assets/slide3.jpg';
import slide4 from '../assets/slide4.jpg';
import slide5 from '../assets/slide5.jpg';
import slide6 from '../assets/slide6.jpg';
import slide7 from '../assets/slide7.jpg';
import slide8 from '../assets/slide8.jpg';
import slide9 from '../assets/slide9.jpg';
import slide10 from '../assets/slide10.jpg';
import slide11 from '../assets/slide11.jpg';

export default function Carousel() {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    { original: slide1, thumbnail: slide1 },
    { original: slide2, thumbnail: slide2 },
    { original: slide3, thumbnail: slide3 },
    { original: slide4, thumbnail: slide4 },
    { original: slide5, thumbnail: slide5 },
    { original: slide6, thumbnail: slide6 },
    { original: slide7, thumbnail: slide7 },
    { original: slide8, thumbnail: slide8 },
    { original: slide9, thumbnail: slide9 },
    { original: slide10, thumbnail: slide10 },
    { original: slide11, thumbnail: slide11 },
  ];

  const openSlider = (index) => {
    setSelectedImage(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
        {images.map((image, index) => (
          <div key={index} className="space-y-2">
            <img
              className="w-full h-auto object-cover cursor-pointer"
              src={image.thumbnail}
              alt="Crooners show"
              onClick={() => openSlider(index)}
              style={{ maxHeight: '100%', width: '100%' }} // Contrôle la taille des images
            />
          </div>
        ))}
      </div>

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-90 z-50 flex justify-center items-center">
          <div className="max-w-4xl w-full h-full relative">
            <ImageGallery
              items={images}
              startIndex={selectedImage}
              showPlayButton={false}
              showFullscreenButton={false}
              showIndex={false}
              showThumbnails={true}
              onClose={closeModal}
              useBrowserFullscreen={true}
              renderFullscreenButton={() => null}
            />
            <button
              className="absolute top-4 right-4 text-white text-2xl focus:outline-none"
              onClick={closeModal}
            >
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
}
