import React from "react";
import "../styles/TheVoice.css";
import posterImage from "../assets/poster-thevoice.png";




function TheVoice() {
  return (
    <div id='thevoice' className="thevoice-player-container">
      <div className="thevoice-section">
        <h2 className="thevoice-section-title">The Voice</h2>
        <div className="thevoice-player-wrapper">
          <div className="thevoice-container">
            <video className="thevoice-player" controls playsInline poster={posterImage}>
              <source src='https://flow31.freeboxos.fr:777/share/Yp0mzj3vLlkrasOc/thevoice1.2.mp4' type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div  className="thevoice-description">
            <h3 className="thevoice-description-title">Audition à l'aveugle</h3>
            <p className="thevoice-description-text">
            Retrouvez l'un des moments fort de la carrière de <span>Fabien Cicoletta</span> avec son audition à l'aveugle inoubliable sur la scène de The Voice.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TheVoice;