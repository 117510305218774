import React from 'react';
import '../styles/Navbar.css'; // Fichier CSS séparé pour les styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/logo1-black-bold.webp'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="logo"><img src={logo} alt="logo" /></div>
      <ul className="nav-links">
        <li><a href="#teaser">Crooners</a></li>
        <li><a href="#thevoice">The Voice</a></li>
        <li><a href="#about">A Propos</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
      <div className='social-link'>
      <a href="https://www.facebook.com/fabien.cicoletta" target="_blank" rel="noopener noreferrer" className="social-icon">
        <FontAwesomeIcon icon={faFacebook} className="logo-icon" />
      </a>
       <a href="https://www.instagram.com/fabien_thevoice11/" target='_blank' rel="noopener noreferrer" className="social-icon">
      <FontAwesomeIcon icon={faInstagram} className='logo-icon' />
    </a> 
    </div>
    </nav>
  );
}

export default Navbar;
