import React from "react";
import BackToTopButton from "../components/BackToTopButton";
import Footer from "../components/Footer";
import Show from "../components/Show";
import Hero from "../components/Hero";
import Contact from "../components/Contact";
import TheVoice from "../components/TheVoice";
import Timeline from "../components/Timeline";
import Navbar from "../components/Navbar";
import Teaser from "../components/Teaser";
import Carousel from "../components/Carousel";






function Home() {
  
  return (
    <header>
     <Navbar />
      <main>
        <Hero />
    

      <Teaser />
        <Show />
        <Carousel />
        <TheVoice />
        <Timeline />
        <Contact />
        <Footer />
      </main>
      <BackToTopButton />
    </header>
  );
}

export default Home;
