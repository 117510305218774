import React from "react";
import posterImage from "../assets/poster-teaser.png"; // Chemin d'importation de l'image
import "../styles/Teaser.css";

export default function Teaser() {
    return (
        <div id='teaser' className="teaser-container">
            <div className="teaser-section">
                <h2 className="teaser-section-title"> Découvrez le Teaser du show 'CROONERS' de <span>Fabien Cicoletta</span>.</h2>
              
                <div className="teaser-player-wrapper">
                    <video className="teaser-player" controls playsInline poster={posterImage}>
                        <source src="https://flow31.freeboxos.fr:777/share/kSx_r6GISgCFcx4Q/y2mate.is%20-%20Teaser%20CROONERS%20Direction%20musicale%20Jean%20Christophe%20DEJEAN%20et%20le%20Capitole%20Swing%20Big%20Band-AN9DQbRZEMo-1080pp-1710444809.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}
