import '../styles/Footer.css'
import logo from '../assets/logo1-black-bold.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer(){
    return(
        <footer className='footer'>
            <div className='logo'>
                <img src={logo} alt="logo" />
            </div>
            <div className="social-icons">
            <a href="https://www.facebook.com/fabien.cicoletta" target='_blank' rel="noopener noreferrer" className="social-icon">
      <FontAwesomeIcon icon={faFacebook} className='logo-icon' />
    </a>
    
    { <a href="https://www.instagram.com/fabien_thevoice11/" target='_blank' rel="noopener noreferrer" className="social-icon">
      <FontAwesomeIcon icon={faInstagram} className='logo-icon' />
    </a> }
  </div>
  <div className='copyright'>
    <small>Copyright © fabien-cicoletta.com. Tout droits réservés.</small>
    <small>Crédit photo <a href="https://bertrandchanfreau.wixsite.com/firminmontegutphotos">Firmin de Montegut</a></small>
  </div>




        </footer>





    )




}

export default Footer






