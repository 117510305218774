import '../styles/Show.css'
import '../styles/EventCard.css'

function Show() {
    return (
      <section id='show' className='show-section'>
         <div className='show-title'><h2>CROONERS</h2></div>
         <div className='show-content'>
          <div className='show-cover-container'>
          <div class="card-box">

{/* <div class='text-show'>Prochains shows</div> 
 <div class="card-container">
  <div class="photo-container">
    <div class="date">
      <div class="day">2</div>
      <div class="month">FEV</div>
      <div class="years">2024</div>
    </div>
    <div class="image"></div>
  </div>
  <div class="info-container">
    <div class="event-name">
    CROONERS – DINER SPECTACLE
    </div>
    <div class="event-location">
     Le Bascala
    </div>
    <div class="event-time">
      20h30
    </div>
    <a href='https://spectacles.le-bascala.com/crooners/' target='_blank' rel='noreferrer'><button className='event-button'>Infos et Réservation</button></a>
  </div>
</div> */}


</div>
          </div>
          <div className='show-description'>
          <p>Après son passage remarqué dans l'émission "The Voice",
<span>Fabien Cicoletta</span> accompagné de son orchestre le Capitole Swing Big Band composé de 15 musiciens sous la direction musicale de Jean Christophe DEJEAN vous présente son nouveau spectacle placé sous le signe du Swing "CROONERS".</p>
<p>Un show mêlant de grands standards de jazz et des titres incontournables. De Sinatra à Michael Bublé, en passant par Elvis Presley, Robbie Williams, et bien d'autres encore, vous retrouverez les plus belles chansons qui ont fait la légende de ce style musical (My Way, Now What My Love, Feeling Good, …).</p>

<p>Un spectacle fort en émotions à ne pas louper !</p>
          </div>
        </div>
     
      </section>
    );
  }
  
  export default Show;