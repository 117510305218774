import '../styles/Hero.css';
import hero from '../assets/hero-removebg.webp';

function Hero() {
  return (
    
      <div className='profil'>
        <img src={hero} alt="profil" />
        <div className='description-hero'>
        <h1>Fabien Cicoletta</h1>
        <h2>Plongez dans l'univers unique de <span>Fabien Cicoletta</span>, chanteur à la voix chaude et puissante.</h2>
        </div>
      </div>
      
     
  );
}

export default Hero;
