import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'

function App() {
  return (
    <main className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
